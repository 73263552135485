.OutfitPreview {
    align-content: center;
    height: 100%;
    min-width: 120px;
    width: 100%;
    display: inline-block;
    background-size: contain;
    overflow-y: hidden;
    top: 0;
    bottom: 0;
}

.ThreeJsViewPort {
    display: flex;
    align-content: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
    vertical-align: center;
    background-color: #ffffff;
}

.mannequinInfo {
    width: 100%;
    bottom: 0;
    position: absolute;
}

.mannequinInfoItem {
    margin: auto;
    padding-bottom: 2px;
    display: block !important;
    text-align: center;
}

.ViewPort {
    vertical-align: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.lds-circle {
    display: flex;
    margin: auto;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: #C0C0C0;
}

.lds-circle>div {
    width: 51px;
    height: 51px;
    margin: auto;
    border-radius: 50%;
    background: black;
    animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.small-circle {
    display: flex;
    margin: auto;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.small-circle>div {
    width: 21px;
    height: 21px;
    margin: auto;
    border-radius: 50%;
    background: silver;
    animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.mannequinInfoItemText {
    color: slategray;
}

@keyframes lds-circle {
    0%,
    100% {
        animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(1800deg);
        animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
        transform: rotateY(3600deg);
    }
}

.controlButtonPanel {
    position: absolute;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    bottom: 10px;
    height: 30px;
}

.controlButtonPanel div {
    position: absolute;
    height: 30px;
    width: 30px;
    background-size: cover;
}

.saveScreenshotButton {
        background-image: url(https://img.icons8.com/windows/1600/screenshot.png);
    left: 10px;
}

.toggleTextButton {
    background-image: url(https://img.icons8.com/windows/1600/align-center.png);
    right: auto;
    left: auto;
}

.toggleImagesButton {
    background-image: url(https://img.icons8.com/windows/1600/black-tie.png);
    right: 10px;
}